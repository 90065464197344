export const BG_PRIMARY = '#D1A6AF';
export const BG_PRIMARY_GRADIENT = '#785731';
export const BG_SECONDARY = '#F4F1E9';
export const BG_ALTERNATIVE = '#053A28';
export const TEXT_PRIMARY = '#FFFFFF';
export const TEXT_SECONDARY = BG_PRIMARY;
export const TEXT_SHADOW = '#795548';

// Icon & Navigation
export const BG_NAVIGATION = `#b4c5d5`;
export const BG_NAVIGATION_ICON_COLOR = `#000`;
export const BTN_COLOR = '#b4c5d5';

// Frame
export const WEDDING_BORDER_COLOR = `#fff`;
// DEFAULT STYLES
export const BUTTON_COLOR_TEXT = '#000';
export const DEFAULT_BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const DEFAULT_OPTIONS_PROPS = {
  style: { color: 'black' },
};
